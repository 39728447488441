<template>
  <transition name="slide">
    <div v-if="show" class="slide-mask" @click.self="$emit('close')">
      <div class="context-menu-container-bottom pb-8">
        <div class="px-6">
          <h1>{{ title }}</h1>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.slide-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.2s ease;
}
.context-menu-container-bottom {
  display: table-cell;
  vertical-align: top;
  background: #fff;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 97vh;
  transition: all 0.2s ease;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow-y: scroll;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  .context-menu-container-bottom {
    transform: translateY(97vh);
  }
}
</style>
