<template>
  <div>
    <label v-if="label" class="text-base font-semibold" :for="id">
      {{ label }}
    </label>
    <textarea
      :id="id"
      :value="value"
      :rows="rows"
      class="px-4 py-2 h-auto border focus:ring-offset-green-600 focus:border-green-600 w-full sm:text-sm border-gray-300 rounded-lg focus:outline-none text-gray-600 text-sm"
      :placeholder="defaultValue"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    id: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 5
    }
  },
  data: () => ({}),
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
