<template>
  <div>
    <app-bar
      title="Nieuwe strippenkaart"
      :show-back-button="true"
      :show-profile-button="false"
    />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Informatie over je strippenkaart</h1>

      <h2>Naam</h2>
      <input-field
        v-model="card.name"
        default-value="Naam van je strippenkaart"
        class="mb-6"
        @input="updateCard"
      />

      <h2>Beschrijving</h2>
      <text-field
        v-model="card.description"
        class="mb-6"
        default-value="Waarvoor kun je deze strippenkaart gebruiken? En wat zijn de voorwaarden voor gebruik?"
        @input="updateCard"
      />

      <h2>Geldigheidsduur</h2>
      <p class="text-sm">
        Geef aan hoe lang de strippenkaart geldig is vanaf de aanschafdatum
      </p>
      <dropdown
        :selected="card.details && card.details.validity_period"
        :disabled="false"
        :items="listOfPeriods"
        empty-option="Kies een geldigheidsduur"
        theme="rounded"
        class="mt-4"
        @on-select="(val) => selectValidity(val)"
      />

      <h2>Prijs van je strippenkaart</h2>
      <input-field
        v-model="card.cost"
        class="w-1/2 mb-6"
        currency="€"
        default-value="0,00"
        type="number"
        step="0.01"
        @blur="transformPrice"
      />

      <h2>Aantal keer te gebruiken</h2>
      <input-field
        v-model="timesToUse"
        class="w-1/2 mb-6"
        type="number"
        default-value="10"
        step="1"
        @input="updateCard"
      />
      <template v-if="card.cost && timesToUse">
        <div
          class="border rounded-lg border-gray-300 p-4 mb-6 text-xs bg-gray-50"
        >
          <ul class="list-item">
            <li>Inviplay rekent een fee van 15% per strippenkaart.</li>
            <li>
              De deelnemer betaalt
              {{ $n(card.cost / timesToUse, 'currency') }} per keer
            </li>
            <li>
              Jij ontvangt {{ $n(card.cost * 0.85, 'currency') }} per
              strippenkaart
            </li>
          </ul>
        </div>
      </template>

      <template v-if="participantIsMerchant && paymentProvider === 'opp'">
        <h2>Rekeningnummer</h2>
        <div
          v-if="merchantIsPending"
          class="p-2 text-xs bg-yellow-200 border rounded-md border-yellow-500 mt-8"
        >
          Je bankrekening nummer wordt op dit moment geverifieerd, je ontvangt
          van ons een mail zodra dit is gebeurd. Doorgaans duurt dit 24 uur,
          mocht het langer duren stuur ons dan even een berichtje op
          contact@inviplay.nl. Je kunt je strippenkaart al wel aanmaken en
          deelnemers kunnen al wel betalen voor jouw strippenkaart. Zodra jouw
          ID is geverifieerd worden deze betalingen uiteraard gewoon aan je
          uitgekeerd.
        </div>
        <div
          v-else-if="merchantIsLiveAndVerified"
          class="p-2 text-xs bg-green-100 border rounded-md border-green-800 mt-8"
        >
          Je bankrekening is geverifieerd, zodra iemand jouw strippenkaart koopt
          ontvang jij je geld op:
          {{ bankAccountNumber }}
        </div>
        <button
          :disabled="!formIsValid"
          class="button button-primary w-full mt-8 mb-8"
          @click="gotoNextStep"
        >
          Volgende
        </button>
      </template>
      <template v-else-if="paymentProvider === 'opp'">
        <id-check-component redirect-to="/cards/new" />
      </template>
      <template v-else>
        <h2>Rekeningnummer</h2>

        <p class="text-sm">
          Geef je IBAN nummer en naam van de rekeninghouder op of pas deze aan
          zodat wij jou snel kunnen uitbetalen zodra iemand jouw strippenkaart
          koopt.
        </p>
        <input-field
          v-model="bankAccountNumber"
          default-value="IBAN nummer"
          class="uppercase mt-4"
        />

        <div class="mt-2 mb-6">
          <input-field
            v-model="bankAccountName"
            default-value="Naam rekeninghouder"
          />
        </div>
        <button
          :disabled="!formIsValid"
          class="button button-primary w-full mt-8 mb-8"
          @click="gotoNextStep"
        >
          Volgende
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import Dropdown from '@/components/Dropdown'
import { mapState, mapGetters } from 'vuex'
import InputField from '@/components/InputField'
import TextField from '@/components/TextField'
import IdCheckComponent from '@/components/IdCheckComponent'
import {
  CREDITCARD_NEW_RESET,
  CREDITCARD_NEW_UPDATE
} from '../../store/actions/creditcard'
import {
  PARTICIPANT_OWN,
  PARTICIPANT_UPDATE_BANKACCOUNT,
  PARTICIPANT_UPDATE_PHONENUMBER
} from '../../store/actions/participant'
import {
  VALIDITY_ONE_YEAR,
  VALIDITY_HALF_YEAR,
  VALIDITY_INDEFINITELY
} from '../../utils/constants'
import api from '@/utils/api'
import config from '@/utils/config'

export default {
  name: 'CreditcardNew',
  components: {
    AppBar,
    SpinnerLoader,
    InputField,
    TextField,
    Dropdown,
    IdCheckComponent
  },
  data() {
    return {
      loading: false,
      card: {},
      timesToUse: undefined,
      phoneNumberError: false,
      listOfPeriods: [
        {
          key: VALIDITY_HALF_YEAR,
          value: 'half jaar'
        },
        {
          key: VALIDITY_ONE_YEAR,
          value: '1 jaar'
        },
        {
          key: VALIDITY_INDEFINITELY,
          value: 'onbeperkt'
        }
      ],
      paymentProvider: config.paymentProvider
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'OrganizeStart') {
      this.$store.commit(CREDITCARD_NEW_RESET)
    }
    next()
  },
  computed: {
    ...mapGetters(['newCreditcard']),
    ...mapState({
      profile: (state) => state.participant.ownProfile
    }),
    formIsValid: function () {
      const ibanRegex = RegExp(/[A-Z]{2}\d{2}[A-Z]{4}\d{10}$/)
      return (
        this.card.name &&
        this.card.description &&
        this.card.cost &&
        this.card.details.validity_period &&
        this.timesToUse &&
        (this.paymentProvider === 'opp' ||
          (this.bankAccountNumber &&
            this.bankAccountName &&
            ibanRegex.test(this.bankAccountNumber.toUpperCase())))
      )
    },
    merchantIsLiveAndVerified() {
      return (
        this.profile.merchant?.compliance?.status === 'verified' &&
        this.profile.merchant?.status === 'live'
      )
    },
    merchantIsPending() {
      return (
        this.profile.merchant?.status === 'new' ||
        this.profile.merchant?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'unverified'
      )
    },
    participantIsMerchant() {
      return (
        this.profile?.merchant?.merchant_uid && this.paymentProvider === 'opp'
      )
    },
    bankAccountNumber() {
      const defaultBankAccount = this.profile?.merchant?.bank_accounts.find(
        (item) => item.is_default
      )
      return this.paymentProvider === 'opp' && defaultBankAccount
        ? defaultBankAccount.account?.account_iban
        : this.profile.bankAccountNumber
    },
    bankAccountName() {
      return this.profile?.bankAccountName
    },
    phoneNumber() {
      return this.profile?.phoneNumber
    }
  },
  watch: {},
  mounted: function () {
    this.card = { ...this.newCreditcard }
    this.timesToUse = this.newCreditcard.details.times_to_use
  },
  created: function () {
    if (Object.keys(this.profile).length <= 0) {
      this.$store.dispatch(PARTICIPANT_OWN)
    }
  },
  methods: {
    transformPrice: function (input) {
      this.card.cost = parseFloat(input.replace(',', '.')).toFixed(2)
      this.updateCard()
    },
    selectValidity: function (val) {
      this.card.details = {
        ...this.card.details,
        validity_period: val
      }
      this.$store.commit(CREDITCARD_NEW_UPDATE, this.card)
      this.updateCard()
    },
    updateCard: function () {
      this.$store.commit(CREDITCARD_NEW_UPDATE, this.card)
    },
    gotoNextStep: function () {
      this.card = {
        ...this.card,
        details: {
          ...this.card.details,
          times_to_use: parseInt(this.timesToUse)
        }
      }
      if (
        this.profile.bankAccountNumber !== this.bankAccountNumber ||
        this.profile.bankAccountName !== this.bankAccountName
      ) {
        this.$store.dispatch(PARTICIPANT_UPDATE_BANKACCOUNT, {
          bankAccountNumber:
            this.bankAccountNumber && this.bankAccountNumber.toUpperCase(),
          bankAccountName: this.bankAccountName
        })
      }
      this.$store.commit(CREDITCARD_NEW_UPDATE, this.card)
      this.$router.push('/cards/new/events')
    },
    storePhoneNumber() {
      setTimeout(() => {
        if (this.isValid()) {
          this.phoneNumberError = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.phoneNumber
          })
        } else {
          this.phoneNumberError = true
        }
      }, 500)
    },
    async createMerchant() {
      this.loading = true
      const response = await api.post(
        `merchant?return_url=${this.$route.path}?action=merchant_verification`
      )
      this.loading = false
      if (response?.data?.bank_accounts?.length) {
        window.location.href = response.data.bank_accounts[0].verification_url
      } else {
        alert('Er gaat iets mis...')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
