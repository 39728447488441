<template>
  <div>
    <app-bar
      title="Nieuwe Strippenkaart"
      :show-back-button="true"
      :show-profile-button="false"
    />
    <spinner-loader v-if="loading" />
    <div v-if="card" class="container">
      <h1>Controleer je strippenkaart</h1>
      <h2>Naam</h2>
      <p>{{ card.name }}</p>
      <h2>Beschrijving</h2>
      <nl2br v-if="card.description" tag="p" :text="card.description" />
      <h2>Geldigheidsduur</h2>
      <p>{{ $t(validity_period) }} na aanschaf van de strippenkaart</p>
      <h2>Prijs</h2>
      <p>{{ $n(card.cost, 'currency') }}</p>
      <h2>Aantal keer te gebruiken</h2>
      <p>{{ card && card.details && card.details.times_to_use }}</p>
      <button class="button button-primary w-full mt-8" @click="saveCard">
        Opslaan
      </button>
    </div>
    <modal :show="showSuccessModal" @close="closeModalAndGotoToCards">
      <template v-slot:header>Gelukt!</template>
      <div>
        Je strippenkaart is aangemaakt en kan nu gekocht worden door andere
        sporters.
      </div>
    </modal>

    <modal :show="showErrorModal" @close="showErrorModal = false">
      <template v-slot:header>Oeps</template>
      <div>
        Er ging iets fout bij het aanmaken van je strippenkaart. Probeer het
        opnieuw of neem anders contact op met contact@inviplay.nl
      </div>
    </modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'
import {
  CREDITCARD_NEW_RESET,
  CREDITCARD_NEW_STORE
} from '../../store/actions/creditcard'
import Modal from '../../components/Modal.vue'
import {
  CREDITCARD_OWNER_EVENTS,
  CREDITCARD_GROUP_EVENTS,
  CREDITCARD_SINGLE_EVENTS,
  VALIDITY_MAPPING
} from '@/utils/constants'
import Nl2br from 'vue-nl2br'

export default {
  name: 'CreditcardReady',
  components: { AppBar, SpinnerLoader, Modal, Nl2br },
  data() {
    return {
      showSuccessModal: false,
      showErrorModal: false,
      card: {}
    }
  },
  computed: {
    ...mapState({
      cardFromState: (state) => state.creditcard.new,
      loading: (state) => state.creditcard.loading
    }),
    validity_period: function () {
      return this.card.details && this.card.details.validity_period
        ? VALIDITY_MAPPING[this.card.details.validity_period]
        : undefined
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.card = { ...this.cardFromState }
  },
  methods: {
    saveCard: async function () {
      if (this.card.type === CREDITCARD_OWNER_EVENTS) {
        this.card.details = {
          ...this.card.details,
          events: undefined,
          groupId: undefined
        }
      } else if (this.card.type === CREDITCARD_GROUP_EVENTS) {
        this.card.details = {
          ...this.card.details,
          events: undefined
        }
      } else if (this.card.type === CREDITCARD_SINGLE_EVENTS) {
        this.card.details = {
          ...this.card.details,
          events: this.card.details.events?.map((item) => item.id)
        }
      }
      const result = await this.$store.dispatch(CREDITCARD_NEW_STORE, this.card)
      if (result) {
        // show success modal
        this.showSuccessModal = true
      } else {
        this.showErrorModal = true
      }
    },
    closeModalAndGotoToCards: function () {
      this.showSuccessModal = false
      if (
        this.card.source === 'group' &&
        this.card.details &&
        this.card.details.groupId
      ) {
        this.$router.push(`/groups/${this.card.details.groupId}`)
      } else {
        this.$router.push('/profile/cards')
      }
      this.$store.commit(CREDITCARD_NEW_RESET)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
