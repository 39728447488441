<template>
  <div>
    <app-bar
      title="Nieuwe strippenkaart"
      :show-back-button="true"
      :show-profile-button="false"
    />
    <spinner-loader v-if="loading" />
    <template v-if="card">
      <div class="container">
        <h1>
          Kies de activiteiten waar deze strippenkaart gebruikt kan worden
        </h1>

        <div class="mt-8">
          <h2>Geselecteerde activiteiten</h2>
          <template v-if="createdUpcomingEvents.length">
            <p class="text-sm">
              Kies een of meerdere activiteiten dit je wil koppelen aan deze
              strippenkaart.
            </p>
            <div
              v-if="card.details.events && card.details.events.length"
              class="divide-y divide-gray-300 border-t border-b mt-8"
            >
              <div
                v-for="event in card.details.events"
                :key="event.id"
                class="py-1"
              >
                <div class="flex items-center">
                  <div class="flex-1">
                    <h2 class="my-0">{{ event.name }}</h2>
                    <p class="text-sm text-gray-400">
                      {{ event.nextDateFormatted }}
                    </p>
                  </div>
                  <div @click="removeSelectedEvent(event.id)">
                    <icon-base size="24">
                      <icon-trash />
                    </icon-base>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="button button-secondary button-sm mt-4"
              @click="openSelectEvents"
            >
              Voeg activiteiten toe
            </button>
          </template>
          <template v-else>
            <p class="text-sm">
              Je hebt nog geen activiteiten georganiseerd of deze zijn inmiddels
              afgelopen. Als je een activiteit organiseerd kun deze
              strippenkaart koppelen aan die activiteit
            </p>
          </template>
        </div>

        <button class="button button-primary w-full mt-8" @click="gotoNextStep">
          Volgende
        </button>
      </div>
    </template>
    <panel
      v-if="isMobile"
      :show="showSelectEvents"
      title="Selecteer activiteit(en)"
      @close="closeSelectEvents"
    >
      <event-select-list
        :events="createdUpcomingEvents"
        :selected-events="selectedEvents"
        @change-selected-events="changeSelectedEvents"
      />
      <button
        class="button button-primary w-full mt-8"
        @click="persistSelectedEvents"
      >
        Opslaan
      </button>
    </panel>
    <modal
      v-else
      :show="showSelectEvents"
      :show-close-icon="true"
      @close="closeSelectEvents"
    >
      <template v-slot:header>Selecteer activiteit(en)</template>
      <event-select-list
        :events="createdUpcomingEvents"
        :selected-events="selectedEvents"
        @change-selected-events="changeSelectedEvents"
      />

      <template v-slot:footer>
        <button class="button button-primary" @click="persistSelectedEvents">
          Opslaan
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapGetters, mapState } from 'vuex'
import Modal from '@/components/Modal'
import IconBase from '@/components/IconBase'
import IconTrash from '@/components/icons/IconTrash'
import EventSelectList from '@/components/EventSelectList'
import { CREDITCARD_NEW_UPDATE } from '@/store/actions/creditcard'
import Panel from '@/components/Panel'
import isMobile from '@/utils/isMobile'
import { EVENT_CREATED_BY_PARTICIPANT } from '../../store/actions/event'
import {
  CREDITCARD_OWNER_EVENTS,
  CREDITCARD_SINGLE_EVENTS,
  CREDITCARD_GROUP_EVENTS
} from '../../utils/constants'
import { GROUP_GET_CREATED_OR_ADMIN } from '../../store/actions/group'

export default {
  name: 'CreditcardSelectEvents',
  components: {
    AppBar,
    SpinnerLoader,
    Panel,
    Modal,
    EventSelectList,
    IconBase,
    IconTrash
  },
  data() {
    return {
      loading: false,
      selectedEvents: [],
      card: {},
      cardType: undefined,
      CREDITCARD_OWNER_EVENTS: CREDITCARD_OWNER_EVENTS,
      CREDITCARD_SINGLE_EVENTS: CREDITCARD_SINGLE_EVENTS,
      CREDITCARD_GROUP_EVENTS: CREDITCARD_GROUP_EVENTS,
      showSelectEvents: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['newCreditcard', 'authId', 'createdUpcomingEvents']),
    ...mapState({
      groupsCreatedOrAdmin: (state) => state.group.groupsCreatedOrAdmin
    }),
    listOfGroups: function () {
      return this.groupsCreatedOrAdmin.map((group) => ({
        key: group.id,
        value: group.name
      }))
    },
    cardIsValid: function () {
      return (
        this.cardType === CREDITCARD_OWNER_EVENTS ||
        this.cardType === CREDITCARD_SINGLE_EVENTS ||
        !!(
          this.cardType === CREDITCARD_GROUP_EVENTS && this.card.details.groupId
        )
      )
    }
  },
  watch: {},
  created: function () {
    this.$store.dispatch(GROUP_GET_CREATED_OR_ADMIN)
    this.$store.dispatch(EVENT_CREATED_BY_PARTICIPANT)
  },
  mounted: function () {
    this.card = { ...this.newCreditcard }
    this.cardType = this.newCreditcard.type || CREDITCARD_SINGLE_EVENTS
    // if (this.card.source === 'group') {
    //   this.gotoNextStep()
    // }
  },
  methods: {
    gotoNextStep: function () {
      this.card = {
        ...this.card,
        type: this.cardType
      }
      this.$store.commit(CREDITCARD_NEW_UPDATE, this.card)
      this.$router.push('/cards/new/ready')
    },
    changeSelectedEvents: function (selectedEvents) {
      this.selectedEvents = [...selectedEvents]
    },
    openSelectEvents: function () {
      this.selectedEvents = [...(this.card.details.events || [])]
      this.showSelectEvents = true
    },
    closeSelectEvents: function () {
      this.selectedEvents = []
      this.showSelectEvents = false
    },
    persistSelectedEvents: function () {
      this.card.details = {
        ...this.card.details,
        events: [...this.selectedEvents]
      }
      this.showSelectEvents = false
    },
    removeSelectedEvent: function (id) {
      this.card.details = {
        ...this.card.details,
        events: this.card.details.events.filter((eventId) => eventId !== id)
      }
    },
    selectGroup: function (val) {
      this.card.details = {
        ...this.card.details,
        groupId: parseInt(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
