<template>
  <div class="has-tab-bar">
    <AppBar title="" :show-back-button="true"></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="container">
      <h1>Gelukt!</h1>
      <p>Je kunt nu gebruik maken van je strippenkaart</p>
    </div>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'

export default {
  name: 'CreditcardConfirmation',
  components: { AppBar, SpinnerLoader, TabBar },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
