<template>
  <div>
    <AppBar title="" :show-back-button="true"></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="container">
      <template v-if="creditcard">
        <h1>Koop je strippenkaart</h1>
        <h2>{{ creditcard.name }}</h2>
        <p class="text-sm">{{ creditcard.description }}</p>
        <div class="border border-gray-400 rounded-lg p-4 mt-4 mb-8">
          <div class="font-bold">
            {{ $n(creditcard.cost, 'currency') }}
          </div>
          <div class="text-sm text-gray-500">
            {{ creditcard.details && creditcard.details.times_to_use }}x te
            gebruiken
          </div>
        </div>
        <h2>Hoe wil je betalen?</h2>
        <p class="text-sm">
          Kies hieronder eerst een betaalmethode, je wordt vervolgens naar de
          pagina van je bank geleid om de betaling af te ronden.
        </p>

        <payment-options
          :banks="banks"
          :paypal-fee="paypalFee"
          @start-payment="startPayment"
        />
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapGetters, mapState } from 'vuex'
import { PAYMENT_GET_METHODS } from '@/store/actions/payment'
import { OPTION_GET } from '@/store/actions/option'
import {
  CREDITCARD_PURCHASE,
  CREDITCARD_GET_ONE
} from '@/store/actions/creditcard'
import PaymentOptions from '@/components/PaymentOptions'

export default {
  name: 'CreditcardPayment',
  components: { AppBar, SpinnerLoader, PaymentOptions },
  data() {
    return {
      loading: false,
      cardId: parseInt(this.$route.params.id),
      origin: this.$route.query.origin
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      paymentMethods: (state) => state.payment.paymentMethods,
      paypalFee: (state) => state.option.options.payments_paypal_fee,
      creditcard: (state) => state.creditcard.selected
    }),
    banks: function () {
      return this.paymentMethods?.length
        ? this.paymentMethods.map((issuer) => ({
            key: issuer.bic,
            value: issuer.name
          }))
        : [] || []
    }
  },
  watch: {},
  created: function () {
    this.$store.dispatch(PAYMENT_GET_METHODS)
    this.$store.dispatch(OPTION_GET, 'payments_paypal_fee')
    this.$store.dispatch(CREDITCARD_GET_ONE, { creditcardId: this.cardId })
  },
  methods: {
    changePaymentMethod: function (issuer) {
      this.paymentMethod = issuer
    },
    startPayment: async function ({ selectedPaymentMethod, issuer }) {
      this.loading = true
      const redirectUrl = await this.$store.dispatch(CREDITCARD_PURCHASE, {
        cardId: this.cardId,
        participantId: parseInt(this.authId),
        method: selectedPaymentMethod,
        issuer: issuer,
        returnUrl: this.origin ? this.origin : null
      })
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
