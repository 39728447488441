<template>
  <div>
    <div v-for="event in events" :key="event.id">
      <div>
        <toggle
          class="my-4"
          :value="
            tempSelectedEvents.findIndex((item) => item.id === event.id) !== -1
          "
          @toggle="(val) => toggleEvent(event, val)"
        >
          <div>
            <h2 class="my-0">{{ event.name }}</h2>
            <p class="text-sm text-gray-600">
              {{ event.nextDateFormatted }}
            </p>
          </div>
        </toggle>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from '@/components/Toggle'

export default {
  name: 'EventSelectList',
  components: { Toggle },
  props: {
    events: {
      type: Array,
      required: true
    },
    selectedEvents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tempSelectedEvents: []
    }
  },
  mounted: function () {
    this.tempSelectedEvents = [...this.selectedEvents]
  },
  methods: {
    toggleEvent: function ({ id, name, nextDateFormatted }, value) {
      this.tempSelectedEvents = value
        ? [...this.tempSelectedEvents, { id, name, nextDateFormatted }]
        : this.tempSelectedEvents.filter((item) => item.id !== id)
      this.$emit('change-selected-events', this.tempSelectedEvents)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
